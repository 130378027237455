import { FC, SVGAttributes, SVGProps } from 'react';
import { theme } from 'twin.macro';

export type Size = 'extra-small' | 'small' | 'medium' | 'large';

export type IconSource = FC<SVGProps<SVGSVGElement>>;

export interface Props extends SVGAttributes<HTMLOrSVGElement> {
	/**
	 * Changes the size of the icon
	 * @default 'medium'
	 */
	size?: Size;
	source: IconSource;
}

const getSize = (size?: Size) => {
	switch (size) {
		case 'extra-small': {
			return {
				height: 12,
				width: 12,
			};
		}
		case 'small': {
			return {
				height: 16,
				width: 16,
			};
		}
		default:
		case 'medium': {
			return {
				height: 24,
				width: 24,
			};
		}
		case 'large': {
			return {
				height: 32,
				width: 32,
			};
		}
	}
};

export const Icon: FC<Props> = ({
	color = theme`colors.gray.700`,
	focusable = false,
	size = 'medium',
	source: SourceComponent,
	...rest
}) => (
	<SourceComponent
		color={color}
		focusable={focusable}
		{...rest}
		{...getSize(size)}
		style={{ flexShrink: 0, ...(rest?.style ?? {}) }}
	/>
);
