import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

import { StyledStack } from './Stack.styles';

export type Align = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
export type Justify = 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
export type Spacing = 'extraTight' | 'tight' | 'loose' | 'mediumLoose' | 'extraLoose' | 'none';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	align?: Align;
	justify?: Justify;
	spacing?: Spacing;
	vertical?: boolean;
	wrap?: boolean;
	children: ReactNode;
	element?: 'div' | 'ul' | 'ol' | 'li' | 'span' | 'aside';
}

export const Stack = forwardRef<HTMLDivElement, Props>(({ children, element, wrap, ...rest }, ref) => {
	const Element = element || 'div';

	return (
		<StyledStack ref={ref} as={Element} wrap={wrap} {...rest}>
			{children}
		</StyledStack>
	);
});

Stack.displayName = 'Stack';
